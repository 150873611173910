import React from "react"
import styled from "styled-components"
import { rem } from "../utils/styling"

const OutlineButton = styled.button`
  font-size: ${rem(18)}rem;
  padding: ${rem(13)}rem ${rem(35)}rem;
  color: white;
  display: inline-block;
  box-sizing: border-box;
  font-weight: ${props => props.theme.primary.weight};
  border: 2px solid white;
  border-radius: ${rem(100)}rem;
  background: none;
  outline: none;
  cursor: pointer;
`
const LinkButton = styled(OutlineButton)`
  padding: ${rem(12)}rem ${rem(10)}rem;
  background: none;
  border: 0;
  color: white;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  font-weight: 600;
  &.active,
  &:hover {
    color: #fbbc62;
    border-bottom: 2px solid #fbbc62;
  }
`

const SolidButton = styled(OutlineButton)`
  background: ${props => props.theme.primary.background};
  color: ${props => props.theme.primary.foreground};
  border: 2px solid ${props => props.theme.primary.background};
`

const SolidSecondaryButton = styled(SolidButton)`
  background: ${props => props.theme.secondary.background};
  color: ${props => props.theme.secondary.foreground};
  border: 2px solid ${props => props.theme.secondary.background};
`

function Button({ children, color, type, ...props }) {
  switch (type) {
    case "outline":
      return <OutlineButton {...props}>{children}</OutlineButton>
    case "link":
      return <LinkButton {...props}>{children}</LinkButton>
    default:
      switch (color) {
        case "secondary":
          return (
            <SolidSecondaryButton {...props}>{children}</SolidSecondaryButton>
          )
        default:
          return <SolidButton {...props}>{children}</SolidButton>
      }
  }
}
export default Button
