export const darkTheme = {
  logo: "light",
  primary: {
    background: "#FCB13F",
    foreground: "#000725",
    weight: 600,
  },
  secondary: {
    background: "#34B6C3",
    foreground: "#ffffff",
    weight: 600,
  },
  banner: {
    background: `radial-gradient(37.28% 48.71% at 48.02% 39.31%, #162B43 0%, #000725 100%)`,
    backgroundMobile: `radial-gradient(37.28% 48.71% at 48.02% 39.31%, #162B43 0%, #000725 100%)`,
    color: "#FFFFFF",
  },
  footer: {
    background: `radial-gradient(50% 50% at 50% 50%, #162B43 0%, #000725 100%)`,
    color: "white",
    highlight: "#34B6C3",
  },
}

export const lightTheme = {
  logo: "dark",
  primary: {
    background: "#FFFFFF",
    foreground: "#34B6C3",
    weight: 400,
  },
  secondary: {
    background: "#34B6C3",
    foreground: "#ffffff",
    weight: 400,
  },
  banner: {
    background: `linear-gradient(206.31deg, #64B2C0 0%, #D3ECF3 100%)`,
    backgroundMobile: `linear-gradient(193.76deg, #D3ECF3 0%, #64B2C0 100%)`,
    color: "#162B43",
  },
  footer: {
    background: `#FAFAFA`,
    color: "#979797",
    highlight: "#162B43",
  },
}
