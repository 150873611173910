import React from "react"
import SrOnly from "../SrOnly/SrOnly"
import darkLogo from "../../images/archimydes_logo.svg"
import lightLogo from "../../images/archimydes_logo_light.svg"

import styled from "styled-components"
import { rem } from "../../utils/styling"
import { Link } from "gatsby"

const Brand = styled(Link)`
  position: relative;
  z-index: 10;
  width: ${rem(147)}rem;
  height: ${rem(18)}rem;
  left: 0;
  top: 0;
  background-size: contain;
  margin: 0;
  display: block;
  background-image: url(${props =>
    props.theme.logo === "light" ? lightLogo : darkLogo});
  background-repeat: no-repeat;
  @media screen and (min-width: 1100px) {
    width: ${rem(206)}rem;
    height: ${rem(24)}rem;
  }
`

const DarkBrandOnly = styled(Link)`
  position: relative;
  z-index: 10;
  width: ${rem(147)}rem;
  height: ${rem(18)}rem;
  left: 0;
  top: 0;
  background-size: contain;
  margin: 0;
  display: block;
  background-image: url(${darkLogo});
  background-repeat: no-repeat;
  @media screen and (min-width: 1100px) {
    width: ${rem(206)}rem;
    height: ${rem(24)}rem;
  }
`

function Logo(props) {
  return (
    <div style={props.isHomepage && { flex: 1 }}>
      <div className="sr-only">Archimydes</div>
      {props.logo === "dark" ? (
        <DarkBrandOnly to="/">
          <SrOnly>Archimydes</SrOnly>
        </DarkBrandOnly>
      ) : (
        <Brand to="/">
          <SrOnly>Archimydes</SrOnly>
        </Brand>
      )}
    </div>
  )
}

export default Logo
